<template>
	<div class="p-3">
		<div class="row">
			<div class="col px-5">
				<form @submit.prevent="save">
					<div class="form-group">
						<h5>Version</h5>
						<input class="form-control" disabled v-model="model.Version"></input>
					</div>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<h5>Players</h5>
								<h6 class="text-small text-muted">How many simulated players we need</h6>
								<input class="form-control" type="number" step="1" min="1" max="100000" v-model="model.Players"></input>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<h5>Max hands</h5>
								<h6 class="text-small text-muted">An average of 6 seconds for hand</h6>
								<input class="form-control" type="number" step="10" v-model="model.MaxHands"></input>
							</div>
						</div>
					</div>

					<div class="row" v-if="config">
						<div class="col">
							<div class="form-group">
								<h5>Bet</h5>
								<select class="form-control" v-model="model.Bet">
									<option :value="coin" v-for="coin in config.coins" v-text="coin"></option>
								</select>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<h5>Paylines</h5>

								<select class="form-control" v-model="model.Paylines">
									<option :value="payline" v-for="payline in config.valid_paylines" v-text="payline"></option>
								</select>
							</div>
						</div>
					</div>

					<div class="form-group">
						<h5>Bankroll</h5>
						<h6 class="text-small text-muted">Initial amount of each player (min suggested
							{{suggestedBankroll}})
							<a href="#" @click.prevent="model.Bankroll = suggestedBankroll" v-if="model.Bankroll !== suggestedBankroll">update</a>
						</h6>
						<input class="form-control" type="number" step="10" v-model="model.Bankroll"></input>
					</div>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<h5>Notes</h5>
								<textarea v-model="model.Notes" class="form-control"></textarea>
							</div>
						</div>
					</div>

					<button type="submit" class="btn btn-primary px-5 py-2" :disabled="saving">
						<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
							  v-if="saving"></span>
						START GAMEPLAY ANALYSIS
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import Multiplier from "@/components/slot-config-editor/prize/multiplier";
	import api from '@/api'
	import Index from "@/components/slot-simulator/index";

	import StandardReportForm from '@/components/reports/types/standard-report-form'
	import StressReportForm from '@/components/reports/types/stress-report-form'
	import ReelFilters from "../../../../../../../components/combinations/reel-filters";
	import ValidPaylinesList from "../../../../../../../components/valid-paylines-list";

	export default {
		components: {ValidPaylinesList, ReelFilters, Index, Multiplier, StandardReportForm, StressReportForm},
		data() {
			return {
				config: null,
				saving: false,
				lines: [],
				model: {
					Players: 10000,
					Paylines: 0,
					Bet: 0,
					MaxHands: 600,
					Bankroll: 0,
					Version: '',
					ProjectConfigfk: null
				}
			}
		},
		async mounted() {
			let tmp = await api.Projects.getProjectConfig(this.$route.params.cuuid)
			this.config = tmp.Configuration

			this.lines = this.config.valid_paylines
			this.model.Bet = this.config.coins[0]
			this.model.Paylines = this.config.valid_paylines[this.config.valid_paylines.length - 1]
			this.model.Version = this.config.version
			this.model.Bankroll = this.suggestedBankroll
			this.model.ProjectConfigfk = tmp.Id
		},
		methods: {
			async save() {
				this.saving = true
				let report = await api.GameplayReports.saveNew(this.model).catch(() => this.saving = false)
				this.$router.push({
					name: 'projects-editor-uuid-cuuid-tests-gameplay-report_id',
					params: {
						report_id: report.Id
					}
				})
				this.saving = false
			}
		},
		computed: {
			suggestedBankroll() {
				return this.model.Bet * this.model.Paylines * 20
			}
		}
	}
</script>
